import React from 'react';
import {
    Icon,
    twitter,
    facebook,
    instagram,
    etsy,
    envelope,
    phone,
} from './icon';
export default function Footer() {
    return (
        <footer>
            <ul className="icons">
                <li>
                    <a
                        href="https://twitter.com/alishasart"
                        rel="external nofollow noopener"
                        className="icon alt"
                        target="_blank"
                    >
                        <Icon icon={twitter} />
                        <span className="label">Twitter</span>
                    </a>
                </li>
                <li>
                    <a
                        href="https://facebook.com/alishasart"
                        rel="external nofollow noopener"
                        className="icon alt"
                        target="_blank"
                    >
                        <Icon icon={facebook} />
                        <span className="label">Facebook</span>
                    </a>
                </li>
                <li>
                    <a
                        href="https://instagram.com/alishasart"
                        rel="external nofollow noopener"
                        className="icon alt"
                        target="_blank"
                    >
                        <Icon icon={instagram} />
                        <span className="label">Instagram</span>
                    </a>
                </li>
                <li>
                    <a
                        href="https://alishasart.etsy.com/"
                        rel="external nofollow noopener"
                        className="icon alt"
                        target="_blank"
                    >
                        <Icon icon={etsy} />
                        <span className="label">Etsy</span>
                    </a>
                </li>
                <li>
                    <a href="tel:+13163024874" className="icon alt">
                        <Icon icon={phone} />
                        <span className="label">Phone</span>
                    </a>
                </li>
                <li>
                    <a
                        href="mailto:alisha.edinborough+alishasart-com@gmail.com"
                        className="icon alt"
                    >
                        <Icon icon={envelope} />
                        <span className="label">Email</span>
                    </a>
                </li>
            </ul>
        </footer>
    );
}
