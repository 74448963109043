import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import { Location } from '@reach/router';

interface HeaderProps {
    siteTitle: ReactNode;
}

const HEAD_SHOT = require('./images/HEAD_SHOT.png');
const LOGO = require('./images/alishas-art.svg');

function HeaderLink(props: { to: string; children: ReactNode }) {
    const { to, children } = props;

    return (
        <Location>
            {({ location }) => (
                <li
                    className={location.pathname === to ? 'current' : undefined}
                >
                    <Link to={to}>{children}</Link>
                </li>
            )}
        </Location>
    );
}

const Header = ({ siteTitle }: HeaderProps) => (
    <header id="header">
        <div className="logo">
            <Link to="/">
                <img src={HEAD_SHOT} alt="Alisha's At" />
                <img src={LOGO} alt="Alisha's Art" />
            </Link>
        </div>
        <nav id="nav">
            <ul>
                <HeaderLink to="/">Home</HeaderLink>
                <HeaderLink to="/about">About</HeaderLink>
                <HeaderLink to="/consignment">Consignment</HeaderLink>
                <HeaderLink to="/contact">Contact</HeaderLink>
            </ul>
        </nav>
    </header>
);

export default Header;
