/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Header from './header';
import './css/layout.css';
import './css/supplemental.css';
import { Dialog, DialogContainer } from './dialog';
import Footer from './footer';

interface LayoutProps {
    style?: 'style1' | 'style2';
    children?: ReactNode;
    banner?: ReactNode;
}

const Layout = ({ children, style, banner }: LayoutProps) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} />
            {banner}
            <div id="wrapper">
                <section className={`main ${style}`}>
                    {children}
                    <Footer />
                </section>
            </div>
            <footer id="footer">
                Copyright &copy; Alisha&rsquo;s Art. All rights reserved.{' '}
                <Link to="/privacy">Privacy</Link>{' '}
                <Link to="/terms">Terms</Link>
            </footer>
            <DialogContainer />
        </>
    );
};

export default Layout;
