import * as React from 'react';

import './Dialog.css';

let _dialogContainer: DialogContainer;
let _uuid = 0;
let _dialogs: Dialog[] = [];

interface DialogOptions {
	title?: JSX.Element | string;
	body?: JSX.Element | string;
	onClose?: () => void;
	footer?: JSX.Element | JSX.Element[];
	ref?: (dlg: Dialog) => void;
	size?: 'sm' | 'md' | 'lg' | 'max' | 'full';
	className?: string;
}

interface DialogProperties extends DialogOptions {
	id?: number;
}

interface DialogState {
	className: string;
	display: string;
	closed: boolean;
	title?: string | JSX.Element;
}

export class Dialog extends React.Component<DialogProperties, DialogState> {
	state: DialogState = { className: '', display: 'none', closed: false };
	public id: number;

	static open(body: string | JSX.Element, options?: DialogOptions) {
		_dialogContainer.open({...options, body });
	}
	
	constructor(props: DialogProperties, context: object) {
		super(props, context);
		this.id = props.id || _uuid++;
	}

	componentDidMount() {
		document.body.classList.add('modal-open');
		requestAnimationFrame(this.open);
		document.addEventListener('keydown', this.handleKeyDown);
		_dialogs.push(this);
	}

	close() {
		this.setClassName('');
		setTimeout(this.handleClosed, 300);
	}

	render() {
		let { body, title, footer, size } = this.props;
		const { className, display, closed } = this.state;
		if (this.state.title) {
			title = this.state.title;
		}

		if (closed) {
			return null;
		}

		return (
			<div
				style={{ display }}
				className={`modal fade ${this.props.className} ${className}`}
				tabIndex={-1}
				role="dialog"
				aria-hidden="true"
			>
				<div className={`modal-dialog modal-${size}`} role="document">
					<div className="modal-content">
						{title && (
							<div className="modal-header">
								<h5 className="modal-title">{title}</h5>
								<button type="button" className="close" aria-label="Close" onClick={this.handleCloseClick}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						)}
						<div className="modal-body">
							{body}
						</div>
						{footer && (
							<div className="modal-footer">
								{footer}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
	
	private handleKeyDown = (e: KeyboardEvent) => {
		if (e.keyCode === 27) {
			this.close();
		}
	}

	private handleClosed = () => {
		this.setState({ closed: true });
		const { id, props: { onClose } } = this;

		_dialogs = _dialogs.filter(x => x.id !== this.id);
		if (_dialogs.length === 0) {
			document.body.classList.remove('modal-open');
			document.removeEventListener('keydown', this.handleKeyDown);
		}
		
		if (onClose) {
			requestAnimationFrame(onClose);
		}
		_dialogContainer.close(id);
	}

	private open = () => {
		this.setState(
			{ display: 'block' },
			() => this.setClassName('show')
		);
	}

	private setClassName = (className: string) => {
		requestAnimationFrame(() => this.setState({ className }));
	}

	private handleCloseClick = () => this.close();
}

interface DialogContainerProperties { }

interface DialogContainerState {
	dialogs: DialogProperties[];
}

export class DialogContainer extends React.Component<DialogContainerProperties, DialogContainerState> {
	state: DialogContainerState = { dialogs: [] };

	constructor(props: DialogContainerProperties, context: object) {
		super(props, context);
		_dialogContainer = this;
	}

	open(dialog: DialogOptions) {
		this.setState(({ dialogs }) => {
			dialogs.push({ ...dialog, id: _uuid++ });
			return { dialogs };
		});
	}

	close (id: number) {
		this.setState(({ dialogs }) => {
			dialogs = dialogs.filter(x => x.id !== id);
			return { dialogs };
		});
	}

	render() {
		const { dialogs } = this.state;
		return dialogs && dialogs.length > 0 ? (
			<div>
				{dialogs.map(dialog => <Dialog key={dialog.id} ref={dialog.ref} {...dialog} />)}
			</div>
		) : null;
	}
}